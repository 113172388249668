<template>
<div>
  <div class="page-banner">
    <img v-lazy="banner" class="img-center">
  </div>
  <div class="container">
    <div class="products padding-t-80">
      <div class="common-title">
        <h1>{{ $t("menu.products") }}</h1>
        <div class="common-line"></div>
      </div>
    </div>
 </div>

<!--  -->
  <div class="product-item" v-for="item in products" :key="item.id" :id="'pro'+item.id">
    <div class="product-item-pic">
      <div class="product-name">
       <p>{{ item.title }}</p>
      </div>
      <img :src="item.image">
    </div>
    <div class="container">
      <div class="pro-text">
        <p>{{item.description}}</p>
      </div>
      <div class="pro-other-txt" v-for="(infos, index) in item.infos" :key="index">
        <h3>{{ infos.title }}</h3>
        <div class="pro-other-txt-list">
          <p v-for="(description, cindex) in infos.description" :key="cindex"><span></span>{{ description.text }}</p>

        </div>
      </div>
    </div>
 </div>


</div>
</template>

<script>

import {getProducts} from "@/network/main";

export default {
  name: "Products",
  data(){
    return{
      banner:{},
      products:[],
    }
  },
  created(){


    getProducts().then(res => {
      this.banner = res.data.data.banner;
      this.products = res.data.data.products;
     })



  },
  mounted() {
     setTimeout(function() { //setTimeout操作dom
      let selectId = localStorage.getItem("toId");
      let toElement = document.querySelector(selectId);
      if (toElement !== null) {
       // console.log(toElement, "toElement");
        toElement.scrollIntoView({
          behavior: "smooth"
        });
        }
    }, 1000);

 },

  methods: {

  },
  deactivated() {
    localStorage.setItem("toId", "");
  },
}
</script>

<style scoped>
.product-item{
 padding-top: 94px;
}
.product-item-pic{
  position: relative;
}

.product-name{

  position: absolute;
  top: 0;
  left: 10%;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;

}

.product-name p{
  font-size: 36px;
  color: #fff;
}

.pro-text{
  padding: 30px 0;
  color: #666;
}



.pro-other-txt-list{
  padding: 10px 0;
}

.pro-other-txt-list{
  display: flex;
  flex-wrap: wrap;
}

.pro-other-txt-list p{
  flex: 0 0 50%;
  max-width: 100%;
  display: flex;
  align-items: center;
  padding: 4px 0;
}

.pro-other-txt-list p span{
  width: 4px;
  height: 4px;
  background: #666;
  border-radius: 100%;
   margin-right: 10px;
}

.pro-other-txt{
  margin-bottom: 10px
}

@media only screen and (min-width:0px) and (max-width:767px){
  .product-item{
    padding-top: 20px;
  }

  .pro-other-txt-list p{
    flex: 0 0 100%;

  }
  .product-name p{
    font-size: 24px;

  }

  .pro-text{
    padding: 15px 0;
    color: #666;
  }


}


</style>